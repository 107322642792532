<template>
  <v-card class="checkout-summary-card py-3 px-3 text-left grey lighten-3" flat>
    <div class="text-h4 font-weight-bold text-uppercase">
      {{
        isOrderDetail ? $t("cartSummary.headerOrder") : $t("cartSummary.header")
      }}
    </div>

    <v-card-text class="text-body-2 pl-0 pr-5">
      <div class="d-flex justify-space-between align-top font-weight-bold">
        <span>{{ $t("cartSummary.totalPrice") }}</span>
        <span>
          {{ $n(netTotal, "currency") }}
        </span>
      </div>
      <div
        v-if="orderCart.totalPriceVariable > 0"
        class=" d-flex justify-space-between align-top text-caption"
      >
        <span class="pr-1"
          >{{ $t("cartSummary.totalPriceVariable")
          }}<v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" x-small color="primary" class="ml-1">
                $info
              </v-icon>
            </template>
            {{ $t("cartSummary.totalPriceVariableTooltip") }}
          </v-tooltip></span
        >
        <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-top mt-3 font-weight-bold"
        v-if="orderCart.packageTotal > 0"
      >
        <span>{{ $t("cartSummary.packageTotal") }}</span>
        <span>{{ $n(orderCart.packageTotal, "currency") }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-top"
        v-for="orderPackage in orderCart.orderPackages"
        :key="orderPackage.orderPackageId"
      >
        <span
          >{{ orderPackage.package.descr }} <br />
          ({{ orderPackage.quantity }}x{{
            $n(orderPackage.package.cost, "currency")
          }})
        </span>
        <span>{{ $n(orderPackage.grossTotal, "currency") }}</span>
      </div>
      <div
        v-if="
          !isOrderDetail &&
            orderCart.giftCertificatesToGenerate &&
            orderCart.giftCertificatesToGenerate.length
        "
      >
        <div class="text-uppercase font-weight-bold mt-4">
          {{ $t("cartSummary.promo") }}
        </div>
        <div
          class="d-flex justify-space-between align-top"
          v-for="gift in orderCart.giftCertificatesToGenerate"
          :key="gift.giftCertificateId"
        >
          <span class="text-caption text-none gift-name">
            {{ gift.name }}
          </span>
          <span v-if="gift.total"> {{ $n(gift.total, "currency") }}</span>
        </div>
      </div>
      <!-- standard gifts 
      -->
      <div
        class="text-uppercase font-weight-bold mt-4"
        v-if="
          filteredStandardGiftCertificate &&
            filteredStandardGiftCertificate.length
        "
      >
        {{ $t("cartSummary.giftCertificates") }}
      </div>
      <div
        v-for="gift in filteredStandardGiftCertificate"
        :key="gift.giftCertificateId"
        class="d-flex flex-row align-center text-caption text-none"
      >
        <!-- v-row
          v-if="(gift.giftCertificateId = 400)"
          no-gutters
          justify="space-between"
        >
          <span class="d-flex justify-space-between align-top">
            <span>
              <v-btn
                icon
                @click="removeGiftCode(gift.userGiftCertificateId)"
                right
              >
                <v-icon color="primary" x-small>$delete</v-icon>
              </v-btn>

              <span class="summary-text">{{ gift.description }} </span>
            </span>
            <span class="summary-text">-{{ giftAmount(gift) }}</span>
          </span>
        </v-row -->

        <v-row
          v-if="gift.giftCertificateId == 400"
          no-gutters
          justify="space-between"
        >
          <span class="gift-name"
            >{{ gift.description }}<br />(viene calcolato alla consegna)
          </span>
          <span>{{ giftAmount(gift) }} </span>
        </v-row>

        <v-row v-else no-gutters justify="space-between">
          <span class="gift-name">{{ gift.name }}</span>
          <span v-if="gift.total > 0">{{
            `-${$n(gift.total, "currency")}`
          }}</span>
        </v-row>

        <v-btn
          v-if="!isOrderDetail && isEditable"
          tile
          icon
          class="delete"
          @click="removeGiftCode(gift.userGiftCertificateId)"
        >
          <v-icon color="promo">$delete</v-icon>
        </v-btn>
      </div>

      <div
        class="d-flex justify-space-between align-top font-weight-bold"
        v-if="orderCart.deliveryFee > 0"
      >
        <span>{{ $t("cartSummary.deliveryFee") }}</span>
        <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
      </div>
      <div
        v-if="discount > 0"
        class="d-flex justify-space-between align-top font-weight-bold"
      >
        <span>{{ $t("cartSummary.discount") }}</span>
        <span> -{{ $n(discount, "currency") }} </span>
      </div>
      <!-- <div
        class="d-flex text-uppercase justify-space-between align-top mt-3 font-weight-bold"
      >
        <span>{{ $t("cartSummary.orderGrossTotal") }}</span>
        <span>{{ $n(orderGrossTotal, "currency") }}</span>
      </div> -->
      <!-- refund -->
      <!-- <div
        class="text-uppercase font-weight-bold mt-4"
        v-if="filteredRefundGiftCertificate.length > 0"
      >
        {{ $t("cartSummary.refund") }}
      </div> -->
      <div
        v-for="gift in filteredRefundGiftCertificate"
        :key="gift.giftCertificateId"
        class="d-flex flex-row align-center text-caption text-none text-uppercase font-weight-bold  mt-2"
      >
        <span class="gift-name">{{ gift.name }}</span>
        <v-spacer />
        <span v-if="gift.total > 0">{{
          `-${$n(gift.total, "currency")}`
        }}</span>
        <v-btn
          v-if="!isOrderDetail && isEditable"
          tile
          icon
          class="delete"
          @click="removeGiftCode(gift.userGiftCertificateId)"
        >
          <v-icon color="promo">$delete</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <div>
      <v-divider class="my-3" />
      <v-row
        no-gutters
        justify="space-between"
        align="center"
        class="text-h4 d-flex font-weight-bold text-uppercase pr-4"
      >
        <span>{{ $t("cartSummary.grossTotalToPay") }}</span>
        {{ $n(orderCart.grossTotal, "currency") }}
      </v-row>

      <v-row
        no-gutters
        justify="space-between"
        align="center"
        v-if="saved > 0"
        class="mt-0 text-caption text-uppercase secondary--text pr-4 font-weight-bold saving"
      >
        <span>{{ $t("cartSummary.saving") }}</span>
        <span>{{ $n(saved, "currency") }}</span>
      </v-row>
    </div>
  </v-card>
</template>
<style lang="scss">
.checkout-summary-card {
  .v-tooltip__content {
    max-width: 300px;
  }
  .delete {
    position: absolute;
    right: -2px;
  }
  .gift-name {
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .saving {
    span {
      color: #cc171a;
    }
  }
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  name: "CheckoutSummary",
  props: {
    orderCart: { type: Object, required: true },
    isOrderDetail: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: true }
  },
  computed: {
    netTotal() {
      if (this.orderCart.orderId && this.orderCart.orderStatusId >= 8) {
        var refundTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_REFUND_TOTAL.totalVariation;
        var discountItemTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_ITEM_TOTAL.totalVariation;
        var discountNetTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_NET_TOTAL.totalVariation;
        return (
          this.orderCart.grossTotal +
          refundTotalDel +
          discountItemTotalDel +
          discountNetTotalDel
        );
      }

      var netTotal = this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
      var discountItemTotal = this.orderCart.orderAdjustmentSummary
        .TYPE_ITEM_TOTAL.totalVariation;
      return netTotal + discountItemTotal;
    },
    discount() {
      var discountItemTotal =
        this.orderCart.orderAdjustmentSummary.TYPE_ITEM_TOTAL_PROMO
          .totalVariation +
        this.orderCart.orderAdjustmentSummary.TYPE_ITEM_TOTAL_GIFT
          .totalVariation;
      return discountItemTotal;
    },
    orderGrossTotal() {
      var refundTotal = this.orderCart.orderAdjustmentSummary.TYPE_REFUND_TOTAL
        .totalVariation;
      return this.orderCart.grossTotal + refundTotal;
    },

    saved() {
      return (
        this.orderCart.orderAdjustmentSummary.TYPE_ITEM_TOTAL.totalVariation +
        this.orderCart.orderAdjustmentSummary.TYPE_NET_TOTAL.totalVariation
      );
      // return this.orderCart.giftTotal + this.orderCart.totalDiscount;
    },
    filteredGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.total > 0;
      });
    },

    filteredStandardGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.giftCertificateId != 51;
      });
    },

    filteredRefundGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.giftCertificateId == 51;
      });
    }
  },
  methods: {
    ...mapActions({
      remove: "cart/removeGiftCode"
    }),
    async removeGiftCode(giftCodeId) {
      await this.remove(giftCodeId);
      this.$emit("removeGiftCode", giftCodeId);
    },
    giftAmount(gift) {
      let value = null;

      if (gift.amount && gift.amount > 0) {
        if (gift.name.indexOf("%") > -1) {
          value = gift.amount + " %";
        } else {
          value = "- " + this.$n(gift.amount, "currency");
        }
      } else if (gift.total && gift.total > 0) {
        value = "- " + this.$n(gift.total, "currency");
      }

      return value;
    }
  }
};
</script>
